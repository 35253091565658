@import '../../../assets/scss/variable.scss';
div.MuiStepper-root.MuiStepper-horizontal {
    div.MuiStep-root.MuiStep-horizontal {
        .MuiStepLabel-labelContainer>.MuiStepLabel-label .box {
            border: 1px solid $light_purple;
            border-radius: 8px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
            min-height: 7.5rem;
            min-width: 150%;
            text-align: left;         

            .box-label{
                font-size: 12px;
                font-weight: 600;
                padding: 5%;
                background: $light_purple;
                .recipt-icon {
                  fill: $red_missing;
                  vertical-align: middle;
                }
            }

            .box-content{
                padding: 5%;
                >p{
                    font-size: 12px;
                }                
            }
        }
    }

    div.MuiStep-root.MuiStep-horizontal:nth-child(2n) {
        .MuiStepLabel-labelContainer>.MuiStepLabel-label .box {
            position: absolute;
            bottom: 5rem;      
        }
    }
}