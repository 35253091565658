@import '../../../assets/scss/table.scss';
@import '../../../assets/scss/variable.scss';
.report-case {
  &.cte5_shelf_life-table {
    tr.MuiTableRow-root.transition_pending {         
        td .caseId,
        td .date-hover  .cds--definition-term,
        td.MuiTableCell-root.MuiTableCell-body {
            color: #9D9D9D;
        }
    }
    .blue-tag-svg{
      margin-right: 2%;
      margin-bottom: -2%;
    }

    .blue-tag-vacant-svg{
      margin-right: 1rem; 
      display: inline-block;
    }

    .MuiTableContainer-root .header .MuiTableCell-head.Admin:nth-child(1) {
      padding-left: 4rem;
    }
  }


  .status-col {
    &.expiry{
      margin-left: -10%;
    }
    svg.expired {
      fill: $red_missing;
      vertical-align: middle;
      padding-right: 5%;
      padding-bottom: 4%; 
    }

    svg.near-expiry {
      fill: $dark_orange_delayed;
      vertical-align: middle;
      margin-right: 2px !important;
    }
  }
  
  .discrepancy-col {
    padding: 1% 2%;
    display: inline-block;
    //width: 102% !important;
    background: #F2EAFF;
    border-radius: 3rem;
    color: #6929C4;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3199999928474426px;
    text-align: left;
    vertical-align: middle;
    margin-left: -1% !important;
  
    svg {
      vertical-align: middle;
      padding-right: 0.1rem !important;
    }
  
    &.FEFO{
      width: 10rem;
    }
    &.Nearing.Expiry{
      svg.expiry{
        width: 24%;
        height: 18%;
        margin-right: 2% !important;
      }
    }
  
    &.FEFO, &.Nearing{
      svg {
        margin-right: 2% !important;
        fill: $light_orange_delayed !important;
      }
    }
  
    &.Expired{
      svg {
        margin-right: 2% !important;
        fill: $red_missing !important;
      }
    }
  }

  td > .discrepancy {
    padding-left: 5%;
  }
}
