@import '../../assets/scss/variable.scss';
//carbon multiselect
.cds--multi-select__wrapper.cds--list-box__wrapper{
    .cds--tag--high-contrast:not(.cds--tag--operational){
        display: none;
        background-color: $purple; 
    }
    .cds--multi-select.cds--list-box.cds--list-box--expanded{
        ul.cds--list-box__menu{           
            li.cds--list-box__menu-item{
                div.cds--list-box__menu-item__option{
                    .cds--checkbox-label[data-contained-checkbox-state=true]::before {
                        background-color: $purple
                    }
                }
            }            
        }
    }
    .cds--multi-select.cds--list-box{
        .cds--list-box__field--wrapper.cds--list-box__field--wrapper--input-focused{
            outline: none;
        }
    }
    .cds--multi-select.cds--multi-select--selected .cds--list-box__field{
        padding-inline-start: 4%;
    }
}

//carbon checkbox
.cds--checkbox:checked + .cds--checkbox-label::before {
    background-color: $purple
}

//carbon tooltip
.cds--tooltip .cds--tooltip-content{
    padding: 0.5rem 1.5rem;
    min-height: 3rem;
    max-height: fit-content;
    font-size: 12px;
    max-inline-size: 13rem;
}

//carbon dropdown
.cds--dropdown__wrapper.cds--list-box__wrapper {
    button.cds--list-box__field{
        &:focus{
            outline: none;
        }
    } 
    ul.cds--list-box__menu{
        z-index: 1200 !important;
        li.cds--list-box__menu-item.cds--list-box__menu-item--active{
                outline: none !important;
        }
    }  
}

//carbon textInput
div.cds--form-item.cds--text-input-wrapper {
    div.cds--text-input__field-wrapper{
        input.cds--text-input{
            &:focus{
                outline: none !important;
            }
        }
    }
}

//mui daterange
.rdrDateRangePickerWrapper .rdrCalendarWrapper {
    .rdrStartEdge, .rdrInRange, .rdrEndEdge{
        color: $purple !important;       
    }
}

//carbon loader
.cds--loading__stroke {
    stroke: var(--cds-interactive, $purple);
}