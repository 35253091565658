@import '../../../assets/scss/variable.scss';
.filter-section {
  display: flex;

  .cds--dropdown__wrapper {
    width: 15%;
    margin-right: 1rem;

    &.dc {
      max-width: 10%;
    }

    &.wrin-no.custom-width {
      width: 12%;
    }

    &.report-name {
      width: 21%;
    }
  }

  .cds--list-box__wrapper {
    width: 15%;
    margin-right: 1rem;
  }

  ul.cds--list-box__menu{
    z-index: 1200 !important;
 }  

  div.clear-filter {
    padding-top: 1rem;
    width: 10%;

    .cds--link {
      color: $purple;
      margin-top: 1rem;
      align-items: center;
      text-decoration: none;
      cursor: pointer;

      svg {
        padding-right: 4px;
      }

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  .cds--form-item.cds--text-input-wrapper {
    width: 15%;
    margin-right: 1rem;
    flex: none;
    margin-top: 1px;
    .cds--text-input{
      padding-left: 5%;
    }
  }

  .date-range-hidden {
    .rdrDateRangePickerWrapper {
      height: auto;

      .rdrCalendarWrapper {
        display: none !important;
      }
    }
  }

  .date-range-display {
    .rdrCalendarWrapper.rdrDateRangeWrapper {
      height: 21rem;
      font-size: 80%;
      display: inline-flex !important;
      right: 100%;
      position: absolute;

      .rdrDayToday .rdrDayNumber span:after {
        background: $purple;
        bottom: 2px;
      }
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span{
      color: white;
      font-weight: 600;
    }

    &.cte2_shelf_life {
      .rdrCalendarWrapper.rdrDateRangeWrapper {
        right: 0%;
        position: relative;
      }
    }

    &.cte4_dc_shelf_life {
      .rdrCalendarWrapper.rdrDateRangeWrapper {
        right: 0%;
        position: relative;
      }
    }

    &.cte4_vs_cte5_order_accuracy-custom-dt-range {
      .rdrCalendarWrapper.rdrDateRangeWrapper {
        right: 0%;
        position: relative;
      }
    }

    &.cte4_vs_cte5-putaway-Restaurant {
      .rdrCalendarWrapper.rdrDateRangeWrapper {
        position: relative;
      }
    }

    &.cte5_shelf_life,
    &.cte5_vs_cte6_compare_daily_inv,
    &.cte5_vs_cte6_consumed_inv {
      .rdrCalendarWrapper.rdrDateRangeWrapper {
        right: 0%;
        position: relative;
      }
    }

    .rdrDateRangePickerWrapper {
      height: 21rem;

      .rdrDefinedRangesWrapper {

        .rdrStaticRange:last-child {
          font-weight: 600;
          color: rgb(61, 145, 255);
        }
      }
    }
  }
  /** Mobile View **/
  @media only screen and (max-width: 768px) {
    .date-range{
      &.date-range.margin-left-putaway {
        .rdrDefinedRangesWrapper {
          width: 54vw !important;
        }
      }    
      &.cte1_vs_cte2 {
        .rdrDefinedRangesWrapper {
          width: 52vw !important;
        }
      }
      &.cte1_vs_cte2-putaway {
        .rdrDefinedRangesWrapper {
          width: 52vw !important;
        }
      }
      &.cte2_shelf_life {
        .rdrDefinedRangesWrapper {
          width: 41vw !important;
        }
      }
      &.cte2_vs_cte3 {
        margin-left: 57% !important;
        .rdrDefinedRangesWrapper {
          width: 50vw !important;
        }
      }
      &.cte3_vs_cte4 {
        .rdrDefinedRangesWrapper {
          width: 54vw !important;
        }
      }   
      &.cte4_dc_shelf_life {
        .rdrDefinedRangesWrapper {
          width: 45vw !important;
        }
      }    
      &.cte4_vs_cte5-putaway {
        margin-left: 67.5% !important;
        .rdrDefinedRangesWrapper {
          width: 52vw !important;
        }
      }
      &.cte4_vs_cte5 {
        margin-left: 49.6% !important;
        .rdrDefinedRangesWrapper {
          width: 53vw !important;
        }
      }
      &.cte5_shelf_life {
        .rdrDefinedRangesWrapper {
          width: 47vw !important;
        }
      }
      &.cte5_vs_cte6_compare_daily_inv {
        .rdrDefinedRangesWrapper {
          width: 43vw !important;
       }  
      }
      &.restaurant_case_transfer {
        margin-left: 54.1% !important;
        .rdrDefinedRangesWrapper {
          width: 32vw !important;
       }
      }
   }
  }
  /** Mobile View Ended */

  .date-range {
    position: absolute;
    margin-top: 4.1rem;
    margin-left: 21.6%;
    z-index: 1000;
    background: $whiteSmoke;
   
    &.margin-left-putaway {
      margin-left: 37.2%;
    }

    &.cte2_vs_cte3-custom-dt-range.margin-left-putaway{
      margin-left: 49%;
    }

    &.cte1_vs_cte2-putaway {
      margin-left: 37.2%;
    }

    &.cte4_vs_cte5-putaway-Restaurant {
      margin-left: 71.3%;
      width: 0vw;
    }

    &.cte4_vs_cte5-putaway-Supplier {
      margin-left: 74.8%;
    }

    &.cte4_vs_cte5-putaway-Admin {
      margin-left: 75.5%;
    }
   
    &.cte1_vs_cte2{
      margin-left: 53%;
    }

    &.cte2_vs_cte3{
      margin-left: 64.7%;
    }

    &.cte3_vs_cte4 {
      margin-left: 52.8%;
    }

    &.cte4_vs_cte5-Admin{
      margin-left: 59.2%;
    }

    &.cte4_vs_cte5-Supplier{
      margin-left: 59.2%;
    }

    &.cte4_vs_cte5-DC{
      margin-left: 67.3%;
    }

    &.restaurant_case_transfer{
      margin-left: 53.1%;
    }

    .rdrInputRanges {
      display: none;
    }

    .rdrDefinedRangesWrapper {
      background: $whiteSmoke;
      width: 14vw;

      .rdrStaticRange {
        color: $dark_grey;
        background: $whiteSmoke;
        border-bottom: 1px solid $powder_ash;
        margin: 0px 1rem;
        padding: 3px 0px;

        &:last-child {

          &:active,
          &:hover,
          &:focus {
            font-weight: 600;
            color: rgb(61, 145, 255);
          }
        }
      }
    }

    .rdrCalendarWrapper {
      background: $whiteSmoke;
      display: inline-flex;
    }

    .applyBtn {
      display: inline-flex !important;
      position: absolute;
      bottom: 5%;
      right: 100%;
      width: 1rem;
      background: $purple;
      --cds-layout-size-height-local: 2rem;
      font-size: 12px;
    }

    .applyBtnHidden {
      display: none !important;
    }

    &.cte2_shelf_life{
      .applyBtn {
        right: 1rem;
      }
    }

    &.cte4_dc_shelf_life{
      .applyBtn {
        right: 1rem;
      }
    }
    
    &.cte5_shelf_life,
    &.cte5_vs_cte6_compare_daily_inv,
    &.cte5_vs_cte6_consumed_inv{
      .applyBtn {
        right: 1rem;
      }
    }

    &.cte4_vs_cte5_order_accuracy-custom-dt-range{
      margin-left: 21.6%;
      .applyBtn {
        right: 1rem;
      }
    }
  }

  .cds--multi-select__wrapper.cds--list-box__wrapper {
    width: 15%;
    margin-right: 1rem;

    &.dispatch-to{
      max-width: 10%;
    }
  }

  .daterange-text.cds--form-item .cds--text-input__field-wrapper .cds--text-input{
    padding-right: 0;
  }

  .search-box {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 1% !important;
    width: 30% !important;
    svg{
      top: 40%;
      position: absolute;
      z-index: 10000;
      left: 2%;
      cursor: pointer;
    }
    .case-input-box{
      width: 85% !important; 
      margin-top: 0;
      .cds--text-input{
       padding-left: 10%;
      }
    }   
  }

  .cds--search-input:focus {
    outline: none;
  }
}