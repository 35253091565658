@import '../../../assets/scss/table.scss';
@import '../../../assets/scss/variable.scss';


.discrepancy-svg {
    fill: $red_missing;
    vertical-align: middle;
    padding-right: 2%;
    position: relative;
    top: -0.1rem;
}

.perpetual_col {
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    color: #6929C4
}

tr.header_2{
  position: sticky;
  top: 3.1vw;
}

tr.header_2 th.total,
td>span.perpetual_col {
  font-weight: 800;
  color: #6929C4;
  font-size: 110%;
}

th.storageCount {
    width: 14vw;    
    // span{
        // position: relative;
        // left: -4rem;
    // }
}

th.total,
th.prep {
  span{
    svg.MuiTableSortLabel-icon{
        display: none;
    }
  }
}

.status-col {
    &.expiry{
      margin-left: -5%;
    }
    svg.expired {
      fill: $red_missing;
      vertical-align: middle;
      padding-right: 5%;
      padding-bottom: 4%; 
    }

    svg.near-expiry {
      fill: $dark_orange_delayed;
      vertical-align: middle;
      margin-right: 2px !important;
    }
  }

.discrepancy-col {
    padding: 1% 4%;
    display: inline-block;
    background: #F2EAFF;
    border-radius: 3rem;
    color: #6929C4;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3199999928474426px;
    text-align: left;
    vertical-align: middle;
    margin-left: -1% !important;
  
    svg {
      vertical-align: middle;
      padding-right: 0.1rem !important;
    }
  
    &.FEFO{
      width: 11rem;
    }
    &.Nearing.Expiry{
      svg.expiry{
        width: 24%;
        height: 18%;
        margin-right: 2% !important;
      }
    }
  
    &.FEFO, &.Nearing{
      svg {
        margin-right: 2% !important;
        fill: $light_orange_delayed !important;
      }
    }
  
    &.Expired{
      svg {
        margin-right: 2% !important;
        fill: $red_missing !important;
      }
    }
  }

  td > .discrepancy {
    padding-left: 5%;
  }