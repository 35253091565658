@import'../../assets/scss/variable.scss';
.reports {
    padding-top: 2rem;
  
    .dropdown-section {
      padding-left: 2rem;
      margin-bottom: 2rem;
    }
  
    .table-section {
      background: $whiteSmoke;
      padding: 1.5% 2% 0;

      .toggle-section{
        display: flex;
        align-items: center;
        height: 2rem;

        .toggle-text{
          font-family: IBM Plex Sans;
          font-size: 125%;
          letter-spacing: 0.32px;
          margin-bottom: 0.7%;
          display: flex;
          align-items: center;
        }
        .cds--toggle__label{
          margin: 0px 0 8px 9px;
          .cds--toggle__switch{
            background-color: var(--cds-toggle-off, $bright_levender);
            box-shadow: none;
          }
          .cds--toggle__switch--checked {
            background-color: var(--cds-support-success, $purple);
         }
         .cds--toggle__check {
          fill: var(--cds-support-success, 
          $purple);
         }
        }
      }     
  
      .legend-section {
        font-family: IBM Plex Sans;
        font-size: 14px;
        display: inline-block;
        width: 100%;
        height: 3rem;
  
        div {
          display: inline-block;
        }
  
        .table-name {
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          text-align: left;
        }
  
        .icon-text {
          vertical-align: middle;
          margin-top: -2rem;
          font-family: 'IBM Plex Sans';
          font-weight: 500;
        }
  
        .float-right {
          display: flex;
          float: right;
          align-items: center;
  
          .cds--checkbox-wrapper {
            display: inline-block;           
            right: 12%;
            margin-top: 1%;
            &.cte4_vs_cte5-top-add {
                top: 0.2rem;
                right: 7% !important;
            }           
             &.cte4_vs_cte5{
              right: 7% !important;
            }
  
            .cds--checkbox:focus+ .cds--checkbox-label::before{
                outline: none;
            }
            .cds--checkbox-label-text {
              padding-inline-start: 0.3rem;
            }
          .cds--tile--selectable{
              padding-inline-end: 0px
          }
          }
          // .checkbox-cte4_vs_cte5{
          //   top: 1rem !important;
          //   right: 7% !important;
          // }
  
          .download-section {
            cursor: pointer;
            vertical-align: middle;
            margin-top: 0.5rem;

            svg{
              vertical-align: super;
              margin-left: 3px;
              top: -0.3rem;
              position: relative;
            }
          }
          .cds--checkbox-wrapper + .download-section{
            &.All {
              margin-top: 0rem !important;
            }
          }

          .under-review {
            margin-right: 1rem;
            margin-top: -0.7rem;

            svg {
              vertical-align: middle;
              margin-right: 0.2rem;
            }
            .blue-tag-svg{
              margin-bottom: 0.2rem;
            }
          }
        }
      }
    }
  }

  .report-container{
    position: relative;

    .view-page{
      padding-top: 4%;
    }
    .back-btn{
      font-weight: 600;
      position: absolute;
      left: 2%;
      top: 2rem;
      color: #6929C4;
      font-size: 100%;
      cursor: pointer;
      align-items: center;
      display: flex;
    }
    .case-journey{
      background: #f4f4f4;
    }
  }

  .download-alert.cds--modal .cds--modal-container{
    width: 25vw;
    height: 17vw;
    overflow: hidden;

    .modal-content{
      padding: 0% 2% !important;
      padding-top: 7% !important;
      padding-bottom: 0% !important;
      text-align: center;
      overflow: hidden;
      .alert-text{
        padding-top: 4%;
      }
    }
  }

  .blue-tag{
    width: 5rem;
    margin-top: -1rem;
    margin-right: 4rem;

    .blue-tag-svg{
      vertical-align: middle;
      display: inline-block;
      margin-right: 4%;
      margin-top: -7%;
    }
  }