@import '../../assets/scss/variable.scss';
//carbon modal
.cds--modal {
    .cds--modal-container {
      background: $white;
      width: 75%;
      height: 35rem;
      border-radius: 1rem;
  
      .cds--modal-header {
        background: $blue_chalk;
        height: 4rem;
        margin-bottom: 0;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
  
        .cds--modal-header__heading {
          font-family: 'IBM Plex Sans';
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0.1599999964237213px;
          text-align: left;
          color: $black;

            .discrepancy-legend {
              margin-left: 1rem;
              padding: 0.3rem 0.7rem;
              border-radius: 3rem;
              font-family: 'IBM Plex Sans';
              font-size: 13px;
              font-weight: 600;
              line-height: 16px;
              text-align: left;
              background: $light_levender;
              color: $purple;
              cursor: pointer;

              svg {
                fill: $red_missing;
                vertical-align: middle;
                position: relative;
                top: -0.1rem;
              }
            }
        }

        .cds--modal-close-button{
            right: 1rem;
            top: 0.5rem;
        }
      }
  
      .cds--modal-content {
        padding: 0;
  
       .modal-content{
          padding: 6rem;
        }
      }
  
      .cds--modal-footer {
        display: none;
      }
    }
  
  }