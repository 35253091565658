@import '../../assets/scss/variable.scss';
.custom-table {
    width: 100%;
    background: white;
    height: 100vh;
    font-family: 'IBM Plex Sans';
    padding-bottom: 5%;
    max-height: calc(100vh - 200px);
  
    .MuiTableContainer-root {
      font-family: 'IBM Plex Sans';
      height: 100vh;
      max-height: inherit;
      padding-bottom: 2.5%; // Added to resolve pagination records

      .header .MuiTableCell-head {
        background-color: $light_purple;
        font-weight: 600;
        font-family: 'IBM Plex Sans';

        &:nth-child(1){
          padding-left: 3rem;
        }
      }

      .header_2 .MuiTableCell-head {
        background-color: #F9F9F9;
      }

      .css-15wwp11-MuiTableHead-root {
        position: sticky;
        top: 0;
        z-index: 10;
    }
      .sub-header .MuiTableCell-head {
        background-color: $lavender_blush;
      }
  
      .MuiTableCell-body {
        background-color: $white;

         &:nth-child(1){
          padding-left: 3rem;
        }
        
        >span.caseId {
          color: $purple;
          cursor: pointer;
        }
  
        >span.discrepancy-col {
          background: $pale_purple;
          padding: 0.3rem 0.5rem;
          border-radius: 3rem;
          font-family: 'IBM Plex Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: $purple;
          cursor: pointer;
          width: 100%;
          margin-left: -6%;
  
          svg {
            fill: $red_missing;
            vertical-align: middle;
            padding-right: 5%;  
          }
        }
  
      }
  
      .MuiTableRow-root.expand-row {
        >td {
          padding: 0rem 15rem;
        }
  
        .MuiTableCell-body {
          background-color: $lavender_blush;
          padding-top: 0rem;
  
          table {
            thead {
              th {  
                font-family: 'IBM Plex Sans';
                font-weight: bold;
  
                &:first-child {
                  padding-left: 3rem;
                }
              }
            }
  
            tbody {
              tr {
                td {
                  line-height: 2.5rem;
                  border-bottom: none;
  
                  &:first-child {
                    padding-left: 3rem;
                  }
                }
              }
            }
          }
        }
      }
    }  
    .MuiTablePagination-root.custom-pagination {
      position: fixed;
      width: 100%;
      bottom: 0;
      background-color: $whiteSmoke;
      .MuiTablePagination-toolbar{
        min-height: 1% !important;
      }
    }
  }
  