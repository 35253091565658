@import '../../../assets/scss/table.scss';
@import '../../../assets/scss/variable.scss';

.receipt-table{
    .caseId{
        color: $purple;
        cursor: pointer;

        .blue-tag-svg{
            margin-right: 2%;
            margin-bottom: -2%;
        }

        .blue-tag-vacant-svg{
            margin-right: 12%; 
            display: inline-block;
        }
    }
    .vl{
        border-left: 1px solid $purple;
        padding: 1.2%;
        margin-left: 3%;
    }

    &.cte4_vs_cte5-table {

        tr.MuiTableRow-root.transition_pending {         
            td .caseId,
            td .date-hover  .cds--definition-term,
            td.MuiTableCell-root.MuiTableCell-body {
                color: #9D9D9D;
            }
        }

        .MuiTableContainer-root .header .MuiTableCell-head:nth-child(1) {
            padding-left: 2.5rem;
            width: 9vw;
        }

        .MuiTableCell-head {
            padding: 6px 0px;
        }
        .MuiTableContainer-root .MuiTableCell-body:first-child {
            padding-left: 1.5rem;
        }

        .MuiTableCell-body {
            padding: 6px 0px;
    
             &:nth-child(1){
              padding-left: 1.5rem;
            }
        }
            
    }

    .discrepancy-col{

        &.bg-none{
            background: none !important;
        }

        &.red_missing svg
        &.Missing svg{
           fill: $red_missing !important;
        }

        &.dark_orange_delayed svg,
        &.Delayed svg{
                fill: $dark_orange_delayed !important;
         }

         &.light_orange_delayed svg{
            fill: $light_orange_delayed !important;
         }

         &.On-Time{            
            padding-left: 2rem !important;
         }
         
         &.Under.Review{
            display: inline-block;
            width: 8rem !important;  
    
                svg {
                  vertical-align: middle;
                  margin-right: 0.2rem;
                }
         }

    }
}