@import '../../assets/scss/variable.scss';
header.MuiPaper-root {
  box-shadow: none;
  height: 5rem;
  padding-top: 0.5%;

  .headerIcon {
    svg {
      position: absolute;
      top: 1.4rem;
    }

    img.header-logo{
      width: 17%;
      padding-right: 3%;
    }

    >span {
      color: $black;
      font-family: IBM Plex Sans;
      font-size: 130%;
      font-weight: 400;
      line-height: 43px;
      text-align: left;
    }
  }

  nav.cds--header__nav {
    position: absolute;
    right: 0;

    .userSVG {
      position: absolute;
      top: 1rem;
      right: 2.2rem;
      cursor: pointer;
    }
    a.cds--header__menu-item {
      background-color: inherit;

      &:hover {
        background-color: inherit;
      }

      &:focus {
        border-color: $blue_chalk ;
      }
      .headerTimestamp{
        font-size: 100%;
        font-style: italic;
        letter-spacing: 0.1599999964237213px;
      }
    }
    a.cds--header__menu-item--current::after {
      background-color: $blue_chalk ;
    }
    ul.cds--header__menu {
      left: -12rem;
      cursor: pointer;
      width: 16rem;
      li:nth-child(1) > a.cds--header__menu-item {
        background-color: $purple;
        color: $white;
      }
      li > a.cds--header__menu-item {
        background-color: $blue_chalk;
      }
      li:not(:nth-child(1)) > a.cds--header__menu-item:hover {
        background-color: $light_purple;
      }
    }
  }

  button.MuiButtonBase-root{
    background: $sky_blue;
  }
}

div.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  background: $sky_blue;

  & > div {
    min-height: 5rem;
  }

  ul.MuiList-root {
    padding-top: 1rem;

    a {
      text-decoration: none;
      li {
        .MuiListItemIcon-root {
          svg {
            fill: $black;
          }
        }
        .MuiListItemText-primary {
          color: $black;
        }
      }
    }
  }
}
