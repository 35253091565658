@import '../../assets/scss/variable.scss';
.login.grid {
  padding: 0;
  margin: 0;
  height: inherit;
  overflow-y: hidden;

  
@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .login-logo{
    width: 50%;
  }

  .login-image {
    right: 34rem !important;
  }
    
  .right_panel {
    height: 100%;
    margin: .5rem 0 !important;
    .description{
      width: 289px !important;
    }
    .title{
      font-size: 20px !important;
    }
  }
  .left_panel{
    .title{
      font-size: 13px !important;
      line-height: 1.2rem !important;
    }
    .description{
      width: 100% !important;
      font-size: 14px !important;
      word-break: break-word !important;
      line-height: 1.3rem !important;
    }
  }
  .cds--text-input__field-wrapper{
    inline-size: 197%;
  }
  .button{
    left: 130% !important;
    padding: 11px 10px;
  }
}


  .right_panel {
    height: 100%;
    margin: 10rem;

    .title {
      font-family: 'IBM Plex Serif' !important;
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      margin-bottom: 7%;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      width: 498px;
      height: 32px;
      margin-bottom: 64px;
    }

    .button {
      left: 80%;
      margin-top: 10%;
      background-color: $purple;
    }

    .errorMsg{
      margin-top: 1rem;
      color: red;
    }
  }

  .left_panel {
    position: relative;
    right: 0;
    bottom: 2rem;
    padding: 0.5rem;
    margin-left: 0 !important;
    font-family: sans-serif;
    font-size: 1.5rem;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $sky_blue;

    .title {
      width: 100%;
      height: 74px;
      margin-top: 71px;
      margin-left: 10%;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 2rem;
      display: flex;
      align-items: center;
    }

    .login-logo{
      margin-top: 20%;
      margin-left: 10%;
      margin-bottom: 3%;
      width: 20%;
    }

    .login-image {
      position: relative;
      width: 54rem;
      right: 23%;
    }

    .description {
      margin-left: 10%;
      width: 22rem;
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 37px;
      line-height: 3rem;
      display: flex;
      align-items: center;
    }   

    .description_2{
      width: 30rem;
    }
    
  }
}
