$red_missing: #DA1E28;
$dark_orange_delayed: #FF8A00;
$light_orange_delayed: #FFC200;
$purple: #6929C4;
$light_purple: #DCC7FF;
$pale_purple: #F2EAFF;
$bright_levender: #B88EFB;
$light_levender: #DECBFF;
$lavender_blush: #f6f2ff;
$blue_chalk: #F1E9FF;
$Seashell: #EEEEEE;
$grey: #BDBDBD ;
$dark_grey: #6f6f6f;
$warm_grey: #8d8d8d;
$sky_blue: #9EF0F0;
$whiteSmoke: #f4f4f4;
$powder_ash: #c6c6c6;
$light_grey: #D9D9D9;
$black: #000;
$white: #fff;
