@import '../../../assets/scss/table.scss';
@import '../../../assets/scss/variable.scss';

.discrepancy-col {
    padding: 1% 2%;
    display: inline-block;
    //width: 102% !important;
    background: #F2EAFF;
    border-radius: 3rem;
    color: #6929C4;
    font-family: IBM Plex Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.3199999928474426px;
    text-align: left;
    vertical-align: middle;
    margin-left: -1% !important;
    margin-right: 2%;
  
    svg {
      vertical-align: middle;
      padding-right: 0.1rem !important;
    }
  
    &.Overage, &.Shortage{
        width: 6rem;
      svg {
        margin-right: 2% !important;
        fill: $light_orange_delayed !important;
      }
    }
  
    &.Missed{
        width: 6rem;
      svg {
        margin-right: 2% !important;
        fill: $red_missing !important;
      }
    }
  }