@import '../../../assets//scss/variable.scss';

.digital-journey .cds--css-grid {
  padding-inline: 0;
  margin-inline: 0;

  .cds--css-grid-column {
    margin-left: 0;

    .cds--aspect-ratio {
      background: #FFFF;
      margin-bottom: 3rem;
      padding-bottom: 2rem;

      .case-id-heading {
        height: 66px;
        background: #F8F2FF;
      }

      .case-id {
        font-family: IBM Plex Sans;
        font-size: 150%;
        font-weight: 600;
        line-height: 43px;
        text-align: left;
        padding-top: 1rem;
        padding-left: 2rem;
      }

      .css-9tmuzo-MuiStepper-root {
        margin-top: 1rem;
        margin-left: 2rem;
      }

      .MuiStepLabel-iconContainer{
        cursor: pointer;
      }

      .MuiStepLabel-labelContainer {
        margin-bottom: 1%;
      }

          
      .purpleRadio,
      .purpleRadio .step-timestamp {
        color: #6929c4;
      }
      
      .defaultRadio,
      .defaultRadio .step-timestamp {
        color: #000;
      }

      .step-timestamp {
        font-family: IBM Plex Sans;
        font-size: 125%;
        font-weight: 600;
        line-height: 20px;
        text-align: left;

        .discrepancy-icon {
          fill: #DA1E28;
          vertical-align: middle;
          margin-left: 1%;
          margin-top: -1%;
        }
      }

      .step-label {
        font-family: IBM Plex Sans;
        font-size: 110%;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
        color: #4F4F4F;
      }

      .step-type {
        padding-top: 1%;
        font-size: 110%;
        font-family: IBM Plex Sans;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #000;
      }

      .step-process {
        font-family: IBM Plex Sans;
        font-size: 110%;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #000;
      }
    }

    .sub-grid {
      height: auto;
      margin-bottom: 3%;
      // box-shadow: 2px 2px 10px 0px #6929C4;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      padding: 1.5%;
      border: 10px solid #6929C4;
      border-radius: 12px;
      padding-top: 2%;
      width: 30vw;

      .row {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;

        &:nth-of-type(2) {
          margin-top: 1%;
        }

        .col-field {
          width: 3vw;
          font-family: IBM Plex Sans;
          font-size: 100%;
          font-weight: 400;
          line-height: 18px;
          text-align: left;

          &.exp-date{
            width: 5vw;
          }
        }

        .description {
          width: 8vw;
        }

        .category {
          width: 6vw;
        }

        .value {
          font-family: IBM Plex Sans;
          font-size: 100%;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #6929C4;
        }
      }
    }

    .grid-1{
      min-height: 65.5vw;
    }

    .grid-2 {
      background: #FFFF;
      padding: 1rem;
      min-height: 60vw;
      width: 30vw;
    
      .details {
        display: flex;
        padding: 3%;
        background: #F8F2FF;

        &:not(:nth-child(1)){
          margin-top: 3%;
        }

        .detail-content {
          display: flex;
          flex-direction: column;

          .label {
            font-family: IBM Plex Sans;
            font-size: 100%;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #707070;           
          }

          .label:not(:nth-child(1)) {
            padding-top: 8%;  
          }

          .field {
            font-family: IBM Plex Sans;
            font-size: 110%;
            font-weight: 600;
            line-height: 18px;
            text-align: left;
            color: #161616;
            padding-top: 0.5%;  
          }

          .discrepancy-col{
            margin-top: 4%;
          }
        }      
      }
    }
  }
}

.discrepancy{
  width: 20vw;
  p{
    margin-right: 2%;
  }

.discrepancy-col {
  padding: 1% 2%;
  display: inline-block;
  width: 8rem;
  background: #DCC4FF;
  border-radius: 3rem;
  color: #6929C4;
  font-family: IBM Plex Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.3199999928474426px;
  text-align: left;
  vertical-align: middle;

  svg {
    vertical-align: middle;
    padding-right: 3% !important;
  }

  &.FEFO{
    width: 11rem;
  }
  &.Nearing.Expiry{
    svg.expiry{
      width: 24%;
      height: 18%;
    }
  }

  &.FEFO, &.Nearing{
    svg {
      fill: $light_orange_delayed !important;
    }
  }

  &.Idle, &.Expired, &.Missing, &.missing_under_review{
    svg {
      fill: $red_missing !important;
    }
  }

  &.dark_orange_delayed,
  &.orange_delayed{
    svg{
      fill: $dark_orange_delayed !important;
    }
  }

  &.light_orange_delayed{
    svg{
      fill: $light_orange_delayed !important;
    }
  }
}
}
.noRecords{
  padding-bottom: 2rem;
    font-size: 125%;
}