@import "../../../assets/scss/variable.scss";
.tiles-section {
    display: flex;
    flex-direction: row;
    margin: 0;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
    background: $Seashell;
    height: 7.188rem;
    margin-bottom: 1%;

    p {
        font-family: IBM Plex Sans;
        font-size: 32px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.1599999964237213px;
        text-align: left;
        margin-top: 0.75rem;

    }

    .cds--tile{
        width: 11rem !important;
        margin-top: 10% !important;
        width: 100%;
        border-right: 1px solid $grey !important;
    }

    fieldset.radio-tile:last-child{
        .cds--tile{
            border-right: none !important;
        }
    }

    .cds--tile__checkmark {
        display: none;
    }

    .show_down {
        color: $purple;
    }

    .tile-border {
        background: $Seashell;
        border: none;
        padding: 5% 15% !important;

        p .show_down {
            visibility: hidden;
        }
    }

    .cds--tile:focus {
        outline: none;
    }

    .cds--tile-input:focus + .cds--tile {
        outline: none;
    }

    .cds--tile--is--selected {
        background-color: $sky_blue;
        border: none;
        height: 7.188rem;
        margin-top: 0rem !important;
        padding: 15%;
    }
}

.cds--tile--is-selected > p .show_down {
    visibility: visible !important;
}